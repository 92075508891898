import React from "react";
import axios from "axios";
import { Col, Row, Table } from "react-bootstrap";
import UserStat from "../../../Components/User/Stat/Modal";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import { __, convertToTime, isMobile, forceSatoshiFormat, decode, getSingleRandomInt, getRandomInt, SITE, Event, isValidNumber } from "../../../../Helper";

class Bets extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bets: [],
            _bets: [],
            fakePlayers: [],
            stop: false
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (this._isMounted) {
            socket.emit(C.LAST_BETS);
            socket.on(C.LAST_BETS, data => this.makeList(decode(data)));
            socket.on(C.ADD_BET, data => this.addBet(decode(data)));

            Event.on('connect', () => {
                this.setState({ stop: false })
            });

            Event.on('disconnect', () => {
                this.setState({ stop: true })
            });

            let self = this;

            axios({
                url: SITE + "/list.json",
                method: 'GET',
            })
                .then(function (req) {
                    let response = req.data;
                    let players = response;

                    var timer = setInterval(function () {
                        if (self.state.stop) {
                            clearInterval(timer)
                        }
                        let select = getSingleRandomInt(players.length);
                        self.addBet(players[select]);
                    }, getRandomInt(2000, 5200));
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    makeList(data) {
        if (this._isMounted) {
            this.setState({ loading: false });
            if (data.length < 1) return;
            if (__.isUndefined(data)) return;
            if (!__.isArray(data)) return;
            data.length = 10;
            this.setState({ bets: data });
        }
    }

    addBet(player) {
        if (this._isMounted) {
            if (player.game !== 'crash' && player.game !== 'heads' && player.game !== 'roulette' && player.game !== 'keno') {
                if (!__.isNumber(player.game)) {
                    this.setState(state => ({ bets: [player, ...state.bets] }));

                    if (this.state.bets.length > 10)
                        this.setState({ bets: __.dropRight(this.state.bets, this.state.bets.length - 10) });
                }
            }
        }
    }

    render() {
        const { t } = this.props;

        const list = this.state.bets.map((player, i) =>
            <MakeBets key={__.toString(i)} data={player} />
        );
        return (
            <>
                {
                    this._isMounted &&
                    <Row>
                        <Col sm={12}>
                            <div className="table-responsive last-bets num-style mb-0 last-bets-index img-fix">
                                {this.state.loading ?
                                    <div className="text-center">
                                        <div className="spinner-grow text-white mt-5" role="status" />
                                    </div>
                                    :
                                    <Table className={"main-table mb-2"}>
                                        <thead className="thead-light">
                                            <tr>
                                                <th>{t('game')}</th>
                                                <th>{t('username')}</th>
                                                <th>{t('amount')}</th>
                                                {!isMobile() &&
                                                    <th>{t('time')}</th>
                                                }
                                                <th>{t('profit')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </Col>
                    </Row>
                }
            </>
        );
    }
}

class MakeBets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: isMobile()
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({ mobile: isMobile() })
    }

    gameDetail = () => {
        Event.emit('single_game_modal', { data: this.props.data })
    }

    render() {
        if (__.isUndefined(this.props.data)) return '';

        let { game, gid, uid, created, name, amount, profit, coin, hash, force } = this.props.data;

        if (__.isUndefined(game)) return '';

        //if is slot game 
        if (isValidNumber(game)) return '';

        coin = __.lowerCase(coin);

        let isWinner = false;

        hash = (hash !== null) ? hash : '0000'

        amount = forceSatoshiFormat(amount, coin, true);
        profit = forceSatoshiFormat(profit, coin, true);

        if (parseFloat(profit) !== 0.00000000 && parseFloat(profit) !== 0)
            isWinner = true;

        game = __.replace(game, '_', ' ');

        let time = convertToTime(Date.now());

        const { t } = this.props;
        return (
            <tr className="q-crash2">
                <td className="cpt transf-capitalize" onClick={this.gameDetail}>
                    <img src={'/assets/images/icons/' + game + '.png'} className={'img-fluid w-20px mr-1'} alt={game} />
                    {!this.state.mobile &&
                        __.upperFirst(__.replace(game, 'singlekeno', 'S-Keno'))
                    }
                </td>
                <td>
                    <i className={'mdi mdi-checkbox-blank-circle align-middle mr-1'} />
                    <UserStat t={t} id={uid} username={name} chart_coin={coin} isWinner={isWinner} />
                </td>
                <td className="num-style cpt" onClick={this.gameDetail}>
                    <img src={'/assets/images/' + coin + '.png'} className={'mini-coin-2'} alt={coin} />
                    {amount}
                </td>
                {!this.state.mobile &&
                    <td className="num-style cpt" onClick={this.gameDetail}>
                        {time}
                    </td>
                }
                <td className={(isWinner === true) ? 'text-success num-style cpt' : 'text-warning num-style cpt'} onClick={this.gameDetail}>
                    <img src={'/assets/images/' + coin + '.png'} className={'mini-coin-2'} alt={coin} />
                    {profit}
                </td>
            </tr>
        );
    }
}

export default Bets;
