const coins = [
  // {
  //   id: 16,
  //   name: "Binance USD",
  //   preffix: "BUSD",
  //   image: "busd.png",
  //   min: "50.00000000",
  //   active: true,
  //   test: false,
  //   network: "BSC",
  // },
  // {
  //   id: 15,
  //   name: "USDC",
  //   preffix: "USDC",
  //   image: "usdc.png",
  //   min: "50.00000000",
  //   active: true,
  //   test: false,
  //   network: "BSC",
  // },
  // {
  //     id: 14,
  //     name: "TrueUSD",
  //     preffix: "TUSD",
  //     image: "tusd.png",
  //     min: '50.00000000',
  //     active: true,
  //     test: false,
  //     network: 'ERC-20'
  // },
  // {
  //     id: 13,
  //     name: "Maker",
  //     preffix: "MKR",
  //     image: "mkr.png",
  //     min: '0.00200000',
  //     active: true,
  //     test: false,
  //     network: 'ERC-20'
  // },
  // {
  //     id: 12,
  //     name: "NEXO",
  //     preffix: "NEXO",
  //     image: "nexo.png",
  //     min: '29.94000000',
  //     active: true,
  //     test: false,
  //     network: 'ERC-20'
  // },
  // {
  //     id: 11,
  //     name: "Pax USD",
  //     preffix: "USDP",
  //     image: "usdp.png",
  //     min: '50.00000000',
  //     active: true,
  //     test: false,
  //     network: 'ERC-20'
  // },
  {
    id: 10,
    name: "Alph Network",
    preffix: "ALPH",
    image: "alph-coin.png",
    min: "1.00000000",
    active: true,
    test: false,
    network: "ERC-20",
  },
  // {
  //     id: 9,
  //     name: "Ripple",
  //     preffix: "XRP",
  //     image: "xrp.png",
  //     min: '2.00000000',
  //     active: true,
  //     test: false,
  //     network: 'BSC'
  // },
  // {
  //     id: 8,
  //     name: "Cardano",
  //     preffix: "ADA",
  //     image: "ada.png",
  //     min: '1.00000000',
  //     active: true,
  //     test: false,
  //     network: 'BSC'
  // },
  //{
  //  id: 7,
  //  name: "Dogecoin",
   // preffix: "DOGE",
    //image: "doge.png",
    //min: "5.00000",
    //active: true,
    //test: false,
    //network: "BSC",
  //},
  // {
  //     id: 6,
  //     name: "TRON",
  //     preffix: "TRX",
  //     image: "tron.png",
  //     min: '10.00000',
  //     active: true,
  //     test: false,
  //     network: 'TRX'
  // },
  // {
  //     id: 5,
  //     name: "Tether",
  //     preffix: "USDT",
  //     image: "tether.png",
  //     min: "50.00000000",
  //     active: true,
  //     test: false,
  //     network: 'ERC-20'
  // },
  // {
  //     id: 4,
  //     name: "Bitcoin Cash",
  //     preffix: "BCH",
  //     image: "bch.png",
  //     min: "0.00050000",
  //     active: true,
  //     test: false,
  //     network: 'BCH'
  // },
  // {
  //     id: 3,
  //     name: "Litcoin",
  //     preffix: "LTC",
  //     image: "ltc.png",
  //     min: "0.00200000",
  //     active: true,
  //     test: false,
  //     network: 'LTC'
  // },
  // {
  //     id: 2,
  //     name: "Ethereum",
  //     preffix: "ETH",
  //     image: "eth.png",
  //     min: "0.00850000",
  //     active: true,
  //     test: false,
  //     network: 'ETH'
  // },
  // {
  //     id: 1,
  //     name: "Bitcoin",
  //     preffix: "BTC",
  //     image: "btc.png",
  //     min: "0.00008000",
  //     active: true,
  //     test: false,
  //     network: 'BTC'
  // },
];

export default coins;
