import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import md5 from "md5";
import ImagePreloader from "image-preloader";
import ReactTooltip from "react-tooltip";
import { Router, BrowserRouter } from "react-router-dom";
import { Col, Modal, Row, Card } from "react-bootstrap";
import { ReactNotifications } from "react-notifications-component";
import i18next from "i18next";
import { I18nextProvider, useTranslation } from "react-i18next";
import history from "./history";
import Route from "./Router";
import socket from "./Socket";
import storage from "./Storage";
import C from "./Constant";
import Layout from "./Layout";
import Details from "./App/Components/User/Stat/Details";
import TipUser from "./App/Components/User/Tip";
import UserLink from "./App/Components/User/Stat/Modal";
import {
  __,
  decode,
  fixDate,
  forceSatoshiFormat,
  Event,
  wait,
  timeConvertor,
  sendNotfication,
  chkd,
  DEVELOPMENT,
} from "./Helper";

i18next.init({
  interpolation: { escapeValue: false },
});

const delay = DEVELOPMENT ? 300 : 2000;

// loading component for suspense fallback
function App() {
  return (
    <Suspense fallback={<></>}>
      <Page />
    </Suspense>
  );
}

function Page() {
  const { t } = useTranslation();
  return <Application t={t} />;
}

class Application extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      welcome: storage.getKey("welcome") ? true : false,
      effect: "d-none",
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.preload();

    socket.on("U2t5cGU6IEBQZWRyb3hhbQ", (key) => this.security(key));

    window.onkeydown = function (e) {
      if (!e.metaKey) {
        // e.preventDefault();
      }
      var char = e.which || e.keyCode || e.charCode;
      if (parseFloat(char) === 123) {
        storage.setKey("mthfk", true);
        Event.emit("mthfk");
      }
    };

    this.security();

    Event.on("security", () => {
      chkd();
    });

    wait(700).then(() => {
      this.setState({ effect: "zoomIn" });
    });

    const h = window.location.host
      ? window.location.host
      : window.location.hostname;
    storage.setKey("key", md5(h));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  preload() {
    var preloader = new ImagePreloader();

    const images = [
      "/assets/images/bg.png",
      "/assets/images/bg2.jpg",
      "/assets/images/dfe.svg",
      "/assets/images/newc.svg",
      "/assets/images/cc12.png",
      "/assets/images/logo4.png",
      "/assets/images/bgg.png",
      "/assets/images/avatar.png",
      "/assets/images/rocket_1f680.png",

      "/land.png",
      "/bomb.png",
      "/safe.png",
      "/search.png",

      "/assets/images/t1.gif",
      "/assets/images/t2.gif",
      "/assets/images/t3.gif",

      "/static/media/hilo_back.png",
      "/static/media/BACK.png",
      "/static/media/vp.svg",
      "/static/media/vp2.svg",

      "/assets/images/table3.png",
      "/assets/images/table.png",
      "/assets/images/dice.png",
      "/assets/images/nt.jpg",

      "/assets/images/btc.png",
      "/assets/images/alph-coin.png",
      "/assets/images/usdt.png",
      "/assets/images/eth.png",
      "/assets/images/ltc.png",
      "/assets/images/bnb.png",
      "/assets/images/bch.png",
      "/assets/images/tron.png",
      "/assets/images/doge.png",
      "/assets/images/ada.png",
      "/assets/images/xrp.png",
      "/assets/images/nc.png",
      "/assets/images/mkr.png",
      "/assets/images/nexo.png",
      "/assets/images/usdp.png",
      "/assets/images/sol.png",

      "/assets/images/slots/fruitburst.jpg",
      "/assets/images/slots/vegasnights.jpg",
      "/assets/images/slots/legendofra.jpg",
      "/assets/images/slots/animalquest.jpg",
      "/assets/images/slots/elvenprincess.jpg",
      "/assets/images/slots/fruitsupernova.jpg",

      "/assets/images/icons/mine.png",
      "/assets/images/icons/wheel.png",
      "/assets/images/icons/classic%20dice.png",

      "/assets/images/covers_new/cd3.svg",
      "/assets/images/covers_new/cc.svg",
      "/assets/images/covers_new/g.svg",
      "/assets/images/covers_new/b.svg",
      "/assets/images/covers_new/lm.svg",
      "/assets/images/covers_new/l.png",
      "/assets/images/covers_new/w3.svg",
      "/assets/images/covers_new/hd.svg",
      "/assets/images/covers_new/wh.svg",
      "/assets/images/covers_new/vv.svg",
      "/assets/images/covers_new/sk.svg",
      "/assets/images/covers_new/kn.svg",
      "/assets/images/covers_new/knn.svg",
      "/assets/images/covers_new/h.svg",
      "/assets/images/covers_new/m.svg",
      "/assets/images/covers_new/mine.svg",
      "/assets/images/covers_new/p.svg",
      "/assets/images/covers_new/singlekeno.svg",
      "/assets/images/covers_new/rl.svg",
      "/assets/images/covers_new/gg.svg",

      "/assets/images/covers_new/_plinko.png",
      "/assets/images/covers_new/__goal.png",
      "/assets/images/covers_new/__limbo.png",
      "/assets/images/covers_new/___mine.png",
      "/assets/images/covers_new/wheel.png",
      "/assets/images/covers_new/____cr1.png",
      "/assets/images/covers_new/____hilo.png",
      "/assets/images/blackjack.png",
      "/assets/images/two.dice.png",
      "/assets/images/vp.png",
      "/assets/images/keno.png",
    ];

    let self = this;

    preloader.preload(images).then(function (status) {
      wait(delay).then(() => {
        self.setState({ loading: false });
        if (!self.state.welcome) {
          // sendNotfication('Register and get 0.00000030 BTC for FREE !', 'info', 'top-center', true);
          // storage.setKey('welcome', true);
        }
      });
    });
  }

  security = (key) => {
    try {
      const a = atob(key);
      console.log(
        `%c` +
        "Please secure your wallet and don't send recovery phrase to any" 
    );
    } catch (e) {}

    chkd();
  };

  render() {
    const { t } = this.props;
    return (
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          {this.state.loading ? (
            <div className="loading">
              <div className="loading-text">
                <img
                  className={"img-fluid animated " + this.state.effect}
                  src="/assets/images/loading.svg"
                  alt="Loading"
                />
                <br />
                <span className="loading-text-words">A</span>
                <span className="loading-text-words">L</span>
                <span className="loading-text-words">P</span>
                <span className="loading-text-words">H</span>
                <span className="loading-text-words">A</span>
                <span className="loading-text-words">.</span>
                <span className="loading-text-words">C</span>
                <span className="loading-text-words">A</span>
                <span className="loading-text-words">S</span>
                <span className="loading-text-words">I</span>
                <span className="loading-text-words">N</span>
                <span className="loading-text-words">O</span>
              </div>
            </div>
          ) : (
            <Router history={history}>
              {this._isMounted && (
                <>
                  <ReactNotifications />
                  <UserModal t={t} />
                  <GameModal t={t} />
                  <SingleGameModal t={t} props={this.props} />
                </>
              )}
              <Layout>
                <Route t={t} history={history} />
              </Layout>
            </Router>
          )}
        </I18nextProvider>
      </BrowserRouter>
    );
  }
}

class SingleGameModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      effect: null,
      details: [],
      cards: [],
      clientName: storage.getKey("name"),
      country: storage.getKey("country") ? storage.getKey("country") : "Global",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    Event.on("single_game_modal", (result) => {
      this.setState({ details: result.data, show: true, effect: "zoomIn" });
    });

    Event.on("single_game_modal_close", (result) => {
      this.handleClose();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClose = () => {
    this.setState({
      detail: true,
      tip: false,
      show: false,
      loading: true,
      effect: "zoomOut",
    });
  };

  verify = () => {
    Event.emit("game_verify", this.state.details.hash);
  };

  render() {
    let {
      uid,
      name,
      amount,
      game,
      profit,
      won,
      coin,
      created,
      hash,
      gid,
      id,
      result,
      cashout,
      slot,
      direct,
      force,
    } = this.state.details;

    let isLost = false;
    let listResult = null;
    let date = fixDate(created);

    cashout = cashout !== undefined ? parseFloat(cashout) / 100 : result;

    if (direct) {
      date = timeConvertor(created);
    }

    // if is fake bot
    if (force) {
      date = "a few minutes ago";
    }

    profit = profit !== undefined ? __.toNumber(profit) : __.toNumber(won);

    if (profit === 0 || profit === 0.0) {
      isLost = true;
    }

    let flex = false;

    if (!__.isUndefined(result)) {
      if (!__.isArray(result)) listResult = result;
      else {
        flex = true;
        listResult = result.map((number, i) => (
          <MakeCircle
            key={__.toString(i)}
            t={this.props.t}
            type={"sm"}
            number={number}
          />
        ));
      }
    }

    listResult = game === "crash" ? cashout : listResult;

    if (game === "crash") {
      if (listResult === "NaN") listResult = "Betting...";
    }

    let selected, last;

    if (game === "hilo") {
      let cards = [];
      selected = result.selected;
      last = result.last;
      let uniq = __.uniq(result.cards);
      for (var i = 0; i < uniq.length; i++) {
        var num = uniq[i];
        var card = (
          <>
            <img
              src={"/assets/images/video-poker/cards/" + num + ".png"}
              className={"img-fluid mt-1"}
              alt="Last Card"
              data-tip={"Card " + num}
            />
          </>
        );
        cards.push(card);
      }
      result = <>{cards}</>;
    }

    /*
if (game === 'roulette') {
    let cards = [];
    result.game_data.forEach(function (value) {
        var card = <><img src={'/assets/images/video-poker/cards/' + value.table + '.png'} className={'img-fluid mt-1'} alt="Last Card" data-tip={'Card ' + (value.table)} /></>;
        cards.push(card);
    });

    result = <>{cards}</>;
}
*/

    if (game === "videopoker") {
      let initialHand = [];
      let finalHand = [];
      let addHoldToFinal = [];

      if (!__.isNull(result)) {
        result.initialHand.forEach((e, i) => {
          var c = e.card;
          var r = parseFloat(c.rank);
          var card = (
            <div
              key={__.toString(i)}
              className={
                e.hold === true ? "handCard-modal holder" : "handCard-modal"
              }
              id="handCardDisplay0"
            >
              <img
                className="img-fluid"
                src={"/assets/images/video-poker/cards/" + r + ".png"}
                alt="Card"
              />
            </div>
          );
          if (c.hold === true) {
            addHoldToFinal.push(c);
          }
          initialHand.push(card);
        });

        let final = __.concat(result.finalHand, addHoldToFinal);

        final.forEach((e, i) => {
          var c = e.card;
          var r = parseFloat(c.rank);
          var card = (
            <div
              key={__.toString(i)}
              className={
                e.hold === true ? "handCard-modal holder" : "handCard-modal"
              }
              id="handCardDisplay0"
            >
              <img
                className="img-fluid"
                src={"/assets/images/video-poker/cards/" + r + ".png"}
                alt="Card"
              />
            </div>
          );
          finalHand.push(card);
        });
      }

      result = (
        <>
          <h4 className="font-12">initial Hand</h4>
          <br />
          <div className="media">{initialHand}</div>
          <br />
          <h4 className="font-12">final Hand</h4>
          <br />
          <div className="media">{finalHand}</div>
        </>
      );
    }

    if (game === "blackjack") {
      let pCards = [];
      let dCards = [];

      if (!__.isNull(result) && __.isArray(result.pCards)) {
        result.pCards.forEach((card, i) => {
          var c = (
            <div
              key={__.toString(i)}
              className="carder up"
              id={"pcard-" + card.value}
            >
              <span className="pos red">
                <span className="rank">{card.rank}</span>
              </span>
            </div>
          );
          pCards.push(c);
        });

        let firstCards = <div id="phand">{pCards}</div>;

        result.dCards.forEach((card, i) => {
          var c = (
            <>
              <div
                key={__.toString(i)}
                className="carder up"
                id={"pcard-" + card.value}
              >
                <span className="pos red">
                  <span className="rank">{card.rank}</span>
                </span>
              </div>
            </>
          );
          dCards.push(c);
        });

        let secondCards = <div id="phand">{dCards}</div>;

        result = (
          <div className="blackjack-modal">
            <div className="first">{firstCards}</div>
            <div className="second">{secondCards}</div>
          </div>
        );
      } else result = result;
    }

    if (__.isNaN(profit)) profit = 0;

    const { t } = this.props;
    return (
      <>
        {this._isMounted && (
          <>
            <Modal
              size="lg"
              backdrop={"static"}
              centered={true}
              show={this.state.show}
              onHide={this.handleClose}
              aria-labelledby="game-modal"
            >
              <Modal.Header>
                {isLost ? (
                  <>
                    <img
                      src="/assets/images/lost.svg"
                      className="img-fluid gd-st"
                      alt="Image"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src="/assets/images/win.svg"
                      className="img-fluid gd-st"
                      alt="Image"
                    />
                  </>
                )}
                <button
                  type="button"
                  className="close p-2"
                  onClick={this.handleClose}
                >
                  <i className={"mdi mdi-close"} />
                </button>
              </Modal.Header>

              <Modal.Body
                className={
                  isLost ? "user-modal-bg p-1" : "user-modal-bg p-1 winImageX"
                }
              >
                <ReactTooltip />
                <Row className="text-center game-modal">
                  <Col lg="6" md="6" sm="12" className="font-12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <UserLink id={uid} username={name} />
                          </div>
                          <div className="r">
                            BET ID:{" "}
                            {gid !== undefined || !gid || gid !== "" ? gid : id}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="text-white font-12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <span className="text-white"> Date / Time</span>
                          </div>
                          <div className="r">{date}</div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <span className="text-white">
                              {" "}
                              BET {t("amount")}
                            </span>
                          </div>
                          <div className="r">
                            <img
                              className="mini-coin-7"
                              src={
                                "/assets/images/" + __.upperCase(coin) + ".png"
                              }
                              alt="Coin"
                            />
                            <b className={"num-style"}>
                              {forceSatoshiFormat(amount)} {__.upperCase(coin)}
                            </b>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <span className="text-white"> {t("profit")}</span>
                          </div>
                          <div className="r">
                            <img
                              className="mini-coin-7"
                              src={
                                "/assets/images/" + __.upperCase(coin) + ".png"
                              }
                              alt="Coin"
                            />
                            <b
                              className={
                                !isLost
                                  ? "num-style text-success"
                                  : "num-style text-danger"
                              }
                            >
                              {forceSatoshiFormat(profit)} {__.upperCase(coin)}
                            </b>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  {game === "crash" ? (
                    <>
                      <Col lg="6" md="6" sm="12">
                        <Card className={"mb-1 brd15"}>
                          <Card.Body className="p-2 text-white text-uppercase">
                            <div className="media">
                              <div className="l">Payout</div>
                              <div className="r">
                                {parseFloat(listResult).toFixed(2)}x
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="6" md="6" sm="12">
                        <Card className={"mb-1 brd15"}>
                          <Card.Body className="p-2 text-white text-uppercase">
                            <div className="media">
                              <div className="l">Result</div>
                              <div className="r">
                                {result ? result + "x" : "Game is Running..."}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  ) : (
                    <>
                      {game === "hilo" ? (
                        <>
                          <Col lg="6" md="6" sm="12">
                            <Card className={"mb-1 brd15"}>
                              <Card.Body className="p-2 text-white text-uppercase">
                                <img
                                  src={"/assets/images/icons/hilo.png"}
                                  className={"img-fluid w-15 single-game"}
                                  data-tip={"hilo"}
                                  alt={"Hilo"}
                                />
                                Result on{" "}
                                <span className="text-warning">{selected}</span>
                                <br />
                                <div className={"mt-2 font-15 hilo-result"}>
                                  {result}
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </>
                      ) : (
                        <>
                          {game === "videopoker" ? (
                            <Col lg="6" md="6" sm="12">
                              <Card className={"mb-1 brd15"}>
                                <Card.Body className="p-2 text-white text-uppercase">
                                  <img
                                    src={"/assets/images/icons/videopoker.png"}
                                    className={"img-fluid w-15 single-game"}
                                    data-tip={"Video Poker"}
                                    alt={"Video Poker"}
                                  />
                                  <div className={"mt-2 font-15 vpoker-result"}>
                                    {result}
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ) : (
                            <>
                              {game === "blackjack" ? (
                                <>
                                  <Col lg="6" md="6" sm="12">
                                    <Card className={"mb-1 brd15"}>
                                      <Card.Body className="p-2 text-white text-uppercase">
                                        <img
                                          src={
                                            "/assets/images/icons/blackJack.png"
                                          }
                                          className={
                                            "img-fluid w-15 single-game"
                                          }
                                          data-tip={"Blackjack"}
                                          alt={"Blackjack"}
                                        />
                                        Result
                                        <br />
                                        <div
                                          className={"mt-2 font-15 hilo-result"}
                                        >
                                          {result}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  {listResult !== null && (
                                    <Col lg="6" md="6" sm="12">
                                      <Card className={"mb-1 brd15"}>
                                        <Card.Body className="p-2 text-white text-uppercase ovh">
                                          <img
                                            src={
                                              "/assets/images/icons/" +
                                              game +
                                              ".png"
                                            }
                                            className={
                                              "img-fluid w-15 single-game"
                                            }
                                            data-tip={game}
                                            alt={game}
                                          />
                                          {game === "crash"
                                            ? "Payout"
                                            : "Result"}
                                          <br />
                                          <div
                                            className={
                                              flex
                                                ? "mt-3 font-15 media center-flex ovh"
                                                : "mt-2 font-15"
                                            }
                                          >
                                            {listResult}
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Col md={game === "crash" ? 12 : 6} sm={6}>
                    {__.toString(hash).length > 0 && (
                      <>
                        <Card className={"mb-1 brd15"}>
                          <Card.Body className="p-1 text-white text-uppercase">
                            <div className="form-group mb-1">
                              <label className="text-white mt-1 crs-def">
                                Check Game
                              </label>
                            </div>
                            {!__.isUndefined(hash) && (
                              <>
                                {(__.isUndefined(slot) || slot === null) && (
                                  <>
                                    <button
                                      onClick={this.verify}
                                      className="btn btn-cs8 btn-block btn-xs btn-block no-shadow"
                                    >
                                      <i className="mdi mdi-shield nts" />{" "}
                                      Verify Result
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          </Card.Body>
                        </Card>
                      </>
                    )}
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      detail: true,
      tip: false,
      notFound: false,
      data: [],
      modal_name: "User Information",
      coin: this.props.coin
        ? this.props.coin
        : storage.getKey("coin") !== null
        ? storage.getKey("coin")
        : "SOL",
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.modal) {
      this.setState({ show: false });
    }
  }

  componentDidMount() {
    socket.on(C.USER_INFO, (data) => this.getUserInfo(decode(data)));

    Event.on("force_modal_user", () => {
      this.setState({ show: true, effect: "zoomIn", haveData: "no", data: [] });
    });

    Event.on("force_modal_tip", () => {
      this.setState({ tip: true, detail: false, modal_name: "Tip User" });
    });

    Event.on("force_modal_tip_close", () => {
      this.setState({
        tip: false,
        detail: true,
        modal_name: "User Information",
      });
    });

    Event.on("single_game_modal_by_chart", () => {
      this.setState({ modal_name: "Game Details" });
    });

    Event.on("back_from_chart", () => {
      this.setState({ modal_name: "User Information" });
    });

    Event.on("close_user_modal", () => {
      this.handleClose();
    });
  }

  handleClose = () => {
    this.setState({
      detail: true,
      tip: false,
      notFound: false,
      show: false,
      effect: "zoomOut",
    });
  };

  getUserInfo = (data) => {
    if (data.status) {
      this.setState({ haveData: "ok", data: data });
    } else {
      this.setState({ notFound: true });
    }
  };

  render() {
    let { chart_coin, t } = this.props;
    chart_coin = chart_coin ? chart_coin : this.state.coin;
    return (
      <Modal
        size="lg"
        backdrop={"static"}
        centered={true}
        show={this.state.show}
        onHide={this.handleClose}
        aria-labelledby="user-modal"
      >
        <Modal.Header className="modal-header px-4">
          {this.state.modal_name}
          <button
            type="button"
            className="close p-2"
            onClick={this.handleClose}
          >
            <i className={"mdi mdi-close"} />
          </button>
        </Modal.Header>
        <Modal.Body className={"user-modal-bg p-1"}>
          {this.state.notFound ? (
            <>
              <div
                className="text-center text-yellow"
                style={{ minHeight: 370 }}
              >
                {/* User Not Found */}[ STATS IS HIDDEN ]
              </div>
            </>
          ) : (
            <>
              {this.state.detail ? (
                <>
                  <div
                    className={
                      this.state.tip === true
                        ? "container-fluid mduser animated fadeOut"
                        : "container-fluid mduser"
                    }
                  >
                    <Details
                      t={this.props.t}
                      haveData={this.state.haveData}
                      name={this.state.data.name}
                      id={this.state.data.id}
                      coin={this.state.coin}
                      data={this.state.data}
                    />
                  </div>
                </>
              ) : (
                <>
                  {this.state.tip === true && (
                    <div className={"container-fluid animated fadeIn"}>
                      <TipUser
                        id={this.state.data.id}
                        name={this.state.data.name}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

class GameModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: true,
      gid: null,
      playerRow: [],
      numbers: [],
      players: [],
      busted: null,
      date: null,
      sha256: null,
      hash: null,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.modal) {
      this.setState({ show: false });
    }
  }

  componentDidMount() {
    socket.on(C.GAME_DETAILS, (data) => this.gameInfo(decode(data)));

    Event.on("force_modal_game", () => {
      this.setState({
        haveData: "no",
        playerRow: [],
        busted: null,
        date: null,
        sha256: null,
        hash: null,
        gid: null,
        loading: true,
        show: true,
        effect: "zoomIn",
      });
    });
  }

  handleClose = () => {
    this.setState({ show: false, loading: true, effect: "zoomOut" });
  };

  gameInfo(response) {
    if (!response.info) {
      this.setState({ show: false, loading: true, effect: "zoomOut" });
      return;
    }
    this.setState({ loading: false, playerRow: [], haveData: "ok" });

    let gameInfo = response.info;
    let busted;

    if (
      gameInfo.busted !== null &&
      gameInfo.busted !== undefined &&
      gameInfo.busted !== "undefined" &&
      gameInfo.busted !== ""
    ) {
      busted = gameInfo.busted;
    } else {
      busted = gameInfo.numbers;
    }

    this.setState({
      busted: busted,
      sha256: md5(gameInfo.hash),
      hash: gameInfo.hash,
      date: gameInfo.date,
      gid: gameInfo.gid,
    });

    let sort = sortByWinner(response.data);
    sort = __.xor(sort);

    let uniq = [];

    sort.forEach((player, i) => {
      var find = __.findIndex(uniq, function (o) {
        return o.uid === player.uid;
      });
      if (find === -1) uniq.push(player);
    });

    this.setState({ players: uniq });
  }

  render() {
    const { t } = this.props;
    let { busted, date, sha256, hash, playerRow, gid, loading } = this.state;
    let heading = "h1";
    let color = busted >= 1.9 ? "success" : "danger";

    if (__.isArray(busted)) {
      heading = "h2";
      busted = busted.map((number, i) => (
        <MakeCircle key={__.toString(i)} t={t} type={"md"} number={number} />
      ));
    }

    var players = this.state.players.map((array, i) => (
      <PlayerRow
        key={__.toString(i)}
        clicked={this.handleClose}
        array={array}
      />
    ));

    return (
      <Modal
        size="md"
        centered={true}
        backdrop={"static"}
        show={this.state.show}
        onHide={this.handleClose}
        aria-labelledby="game-md-modal"
        className={"animated " + this.state.effect}
      >
        <Modal.Header className="Header">
          {t("game_stats")}{" "}
          <button
            type="button"
            className="close p-2"
            onClick={this.handleClose}
          >
            <i className={"mdi mdi-close"} />
          </button>
        </Modal.Header>
        {this.state.players && (
          <Modal.Body>
            {loading ? (
              <>
                <div className="text-center" style={{ minHeight: 200 }}>
                  <div
                    className="spinner-grow text-white my-1 user-loader"
                    role="status"
                  />
                </div>
              </>
            ) : (
              <>
                <Row className="text-darker pt-0 mb-1 user-detail">
                  <Col md={12} sm={12}>
                    <div className="review-box align-item-center p-0">
                      {heading === "h1" ? (
                        <>
                          <h1 className={"text-center my-0 py-0 text-" + color}>
                            {busted}
                            <span className={"font-"}>x</span>
                          </h1>
                          <h5 className={"text-center mt-1 pt-0 text-" + color}>
                            {t("busted")}
                          </h5>
                        </>
                      ) : (
                        <h2 className={"text-center my-0 py-0 text-" + color}>
                          {busted}
                        </h2>
                      )}
                      <Row className="my-3">
                        <Col md={6} sm={6} className="text-success">
                          Game ID: {gid}
                        </Col>
                        <Col md={6} sm={6} className="text-white text-right">
                          {t("date")}: {fixDate(date)}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} sm={12} className="text-center">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text h-40 bg-cs44">
                            HASH
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control no-radius pointer mb-2 bg-cs44"
                          value={__.toString(hash)}
                          readOnly={true}
                        />
                      </div>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text h-40 bg-cs44">
                            MD5
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control no-radius pointer bg-cs44"
                          value={__.toString(sha256)}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {loading ? (
              <>
                <div className="text-center" style={{ minHeight: 200 }}>
                  <div className="text-info my-2 user-loader" role="status" />
                </div>
              </>
            ) : (
              <>
                <h4 className={"mb-3 mt-0 text-yellow"}>Players List</h4>
                <div className="table-responsive game-stats">
                  <table className="table">
                    <thead className="table-header">
                      <tr>
                        <th>{t("player")}</th>
                        <th>{t("bet")}</th>
                        <th>{t("profit")}</th>
                      </tr>
                    </thead>
                    <tbody>{players}</tbody>
                  </table>
                  {players.length === 0 && (
                    <p className="text-center text-muted">
                      No one played on this round
                    </p>
                  )}
                </div>
              </>
            )}
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

function MakeCircle(props) {
  const copyHash = (value) => {
    var t = document.createElement("input");
    t.value = value;
    document.body.appendChild(t);
    t.select();
    t.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(t);
    sendNotfication("Copied !", "info", "top-center");
  };

  let result = props.number;

  if (!__.isUndefined(result.num)) {
    return (
      <>
        <div
          className={
            "avatar-box align-self-center mx-1 my-1 cpt thumb-" + props.type
          }
          data-tip={props.t("click_to_copy_hash") + ":" + result.hash}
          onClick={(e) => copyHash(result.hash)}
        >
          <span className="avatar-title bg-purple rounded text">
            {result.num}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={"avatar-box align-self-center mx-1 my-1 thumb-" + props.type}
      >
        <span className="avatar-title bg-purple rounded text">{result}</span>
      </div>
    </>
  );
}

class PlayerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { array, key, clicked } = this.props;

    let color = "text-success";
    let profit = __.toNumber(array.profit);
    let coin = __.upperCase(array.coin);

    if (profit === 0 || profit === 0.0 || profit === "0.00000000") {
      profit = "- " + array.amount;
      color = "text-warning";
    }

    return (
      <>
        <tr className={color} key={key}>
          <td>
            <UserLink
              id={array.uid}
              clicked={clicked}
              username={array.name}
              isWinner={color}
            />
          </td>
          <td className="num-style">
            <img
              src={"/assets/images/" + coin + ".png"}
              className={"mini-coin-8"}
              alt={coin}
            />
            {forceSatoshiFormat(array.amount)} {coin}
          </td>
          <td className="num-style">
            <img
              src={"/assets/images/" + coin + ".png"}
              className={"mini-coin-8"}
              alt={coin}
            />
            {forceSatoshiFormat(profit)} {coin}
          </td>
        </tr>
      </>
    );
  }
}

function sortByWinner(input) {
  function r(c) {
    return c.profit ? -c.profit : null;
  }
  return __.sortBy(input, r);
}

App.propTypes = {
  coin: PropTypes.string,
  chart_coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  chart_coin: state.items.chart_coin,
});

export default connect(mapStateToProps, {})(App);
