import MyEmitter from "events";
import Swal from "sweetalert2";
import socket from "../../../Socket";
import storage from "../../../Storage";
import C from "../../../Constant";
import {forceSatoshiFormat, decode, encode, Game, usdToCrypto} from "../../../Helper";

/**
 * Game Engine
 */
function Engine(){

    let self = this;

    /**
     * Game Name
     */
    self.game = null;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Game Status
     * 1 type: started
     */
    self.gameStatus = null;

    /**
     * Intialize Auto Mode
     */
    self.init = false;

    /**
     * Current User Coin
     */
    self.coin = null;

    /**
     * Round amount
     */
    self.amount = null;

    /**
     * Bets Number
     */
    self.betNumber = 10;

    /**
     * Increase Amount on Win
     */
    self.onWin = 0;

    /**
     * Increase Amount on Lost
     */
    self.onLost = 0;

    /**
     * Played Game Rounds
     */
    self.played = 0;

    /**
     * Roll Type
     */
    self.type = "Under";

    /**
     * Player is the Win or Lost
     */
    self.isWin = false;

    /**
     * Stop Playing if Won Amount
     */
    self.stopOnWin = 0;

    /**
     * Round Profit
     */
    self.roundProfit = 0;

    /*
     * Payout Range / Default is 1.9800
    */
    self.payout = 1.9800;

    /*
     * Chance is Range
    */
    self.chance = 50;

    /*
     * Start Engine
    */
    self.started = true;

    if(self.started)
    {
        self.ws.on(C.PLAY_CLASSIC_DICE, (data) => {
            data = decode(data);

            switch(data.command)
            {
                case 'play':
                    return self.trigger.emit(C.PLAY_CLASSIC_DICE, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_CLASSIC_DICE, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_CLASSIC_DICE, data);
            }
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.PLAY_CLASSIC_DICE);
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Start Playing Game
 */
Engine.prototype.start = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 0.00000010){
        self.amount = forceSatoshiFormat(0.00000010)
    }
    var amount = self.amount;

    // if(Game['usd_format']){
    //     amount = usdToCrypto(self.amount, self.coin);
    // }

    self.ws.emit(C.PLAY_CLASSIC_DICE, encode({
        game: self.game,
        coin: self.coin,
        amount: amount,
        type: self.type,
        payout: self.payout,
        chance: self.chance
    }));
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
    let self = this;

    if(!self.init)
        return;

    self.played++;

    if(parseInt(self.betNumber) <= 0){
        self.played = 0;
        self.trigger.emit('stop_classic_dice');
        return;
    }

    // Stop On Bet Number
    if(self.played === parseInt(self.betNumber))
    {
        self.played = 0;
        self.trigger.emit('stop_classic_dice');
        return;
    }

    // Increase Amount on Win (player set increase amount on win in %)
    if(self.onWin !== 0)
    {
        // It Mean Player has win in this round
        if(self.isWin)
        {
            self.amount *= parseInt(self.onWin);
        }
    }

    // Increase Amount on Lost (player set increase amount on lost in %)
    if(self.onLost !== 0)
    {
        // It Mean Player has lost in this round
        if(!self.isWin)
        {
            self.amount *= parseInt(self.onLost);
        }
    }

    //Stop On Win (player set amount to stop on win)
    if(self.stopOnWin !== 0)
    {
        if(forceSatoshiFormat(self.stopOnWin) >= forceSatoshiFormat(self.roundProfit)){
            self.played = 0;
            self.roundProfit = 0;
            self.trigger.emit('stop_classic_dice');
            return;
        }
    }

    if(self.amount < 0.00000010){
        self.amount = forceSatoshiFormat(0.00000010)
    }

    self.ws.emit(C.PLAY_CLASSIC_DICE, encode({
        game: self.game,
        coin: self.coin,
        amount: self.amount,
        type: self.type,
        payout: self.payout,
        chance: self.chance
    }));
};

/** Start Game Engine **/
export default Engine;
