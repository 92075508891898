import React from "react";
import ReactTooltip from "react-tooltip";

export default class Social extends React.Component {
    render() {
        return (
            <>
                <span className="btn-social-icon socials">
                    <ReactTooltip />
                    <a href="https://decentilized-casino.gitbook.io/decentalized-casino-network/" target={'_blank'} data-tip={'Documentation'}>
                        <i className={'mdi mdi-file-document font-25'} style={{ color: 'rgb(93 120 127)' }} />
                    </a>
                    <a href="https://github.com/alphadev7" target={'_blank'} data-tip={'Github News'}>
                        <i className={'mdi mdi-github-box font-25'} style={{ color: 'rgb(93 120 127)' }} />
                    </a>
                    <a href="https://t.me/alphasocial" target={'_blank'} data-tip={'Telegram Channel'}>
                        <i className={'mdi mdi-telegram font-25'} style={{ color: 'rgb(93 120 127)' }} />
                    </a>
                    
                </span>
            </>
        );
    }
}
