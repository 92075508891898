import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class Bonus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Bonus - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, Alpha.Casino Slot Games" />
                    <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/bonus" />
                </Helmet>
                <div>
                    <Card className="levbg bg-dark text-white">
                        <Card.Body>
                            <h1 className="text-center">Level Up Rewards</h1>
                            <Card.Text className="font-17 font-weight-bold text-center">
                                Collect all medals and get rewarded !
                            </Card.Text>
                            <div className={"medals-view mt-5"}>
                                <Row>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-2.svg" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple px-3">1</span>
                                                </div>
                                                Required Minimum 100 USD Deposit
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-3.svg" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple px-3">2</span>
                                                </div>
                                                Required Minimum 500 USD Deposit
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-7.svg" height="45" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple px-3">3</span>
                                                </div>
                                                Required Minimum 2000 USD Deposit
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-6.svg" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple px-3">4</span>
                                                </div>
                                                Required 10 Times Rain
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="12">
                                        <div className="media my-2">
                                            <img src="/assets/images/badges/badge-8.svg" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple px-3">5</span>
                                                </div>
                                                Required 2500 USD Profit
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}
