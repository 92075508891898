import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { isMobile } from "../../../../Helper";
import DepositAndWithdraw from "./DepositAndWithdraw";
import { Typography } from "@mui/material";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      wallet: null,
      headerText: "",
      paddingHeader: "",
    };
    this.handleWallet = this.handleWallet.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleWallet() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <>
        <button
          onClick={() => this.handleWallet()}
          className={"dropdown-item"}
          style={{ width: "100%" }}
        >
          <i className="dripicons-wallet text-muted mr-2 text-drop" />
          Wallet
        </button>
        <Modal
          size="lg"
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="wallet-lg-modal"
          dialogClassName="dialog-container"
        >
          <Modal.Header className={this.state.paddingHeader}>
            <Typography style={{ fontSize: 18, textTransform: "capitalize" }}>
              Wallet
            </Typography>
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body className="wallet-modal">
            <DepositAndWithdraw isOpen={this.state.show} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Main;
