import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __, isMobile, wait, Event, decode, sendNotfication } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";

class Games extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            col: 'col-12',
            mdCol: 2,
            isMin: false,
            loading: true,
            games: []
        };
    }

    componentDidMount() {
        socket.emit(C.GAMES)

        if (isMobile()) {
            this.setState({ col: 'col-6' });
        }

        window.addEventListener('resize', this.handleResize);

        wait(50).then(() => {
            this.handleResize();
        })

        Event.on('hide_games', () => {
            wait(50)
                .then(() => {
                    this.handleResize();
                });
        })

        socket.on(C.GAMES, (data) => {
            this.setState({ games: decode(data), loading: false });
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const pageContent = document.querySelector('#page-content');

        if (pageContent !== null) {
            const width = parseFloat(pageContent.style.width);

            if (width < 700) {
                this.setState({ mdCol: 3, isMin: true });
            }
            else {
                this.setState({ mdCol: 3, isMin: false });
            }
        }
    }

    render() {
        let { games, col, mdCol, isMin } = this.state;
        const list = games.map((game, i) =>
            <AddGame key={__.toString(i)} col={col} mdCol={mdCol} item={game} isMin={isMin} />
        );
        return (
            <>
                <Row className={'home-game'}>
                    {
                        this.state.loading ?
                            <>
                                <div className="ycenter text-center">
                                    <div className="spinner-grow text-white my-5" role="status" />
                                </div>
                            </>
                            : list
                    }
                </Row>
            </>
        );
    }
}

class AddGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    maintance = () => {
        sendNotfication("Under maintenance");
    }

    render() {
        let { mdCol, item, isMin } = this.props;
        let style;
        
        // if (item.prefix === 'diamond') {
        //     mdCol = 6
        //     style = {
        //         width: 'auto'
        //     }
        // }

        let disabled = false;

        if(item.prefix === 'blackjack'){
            disabled = false;
        }

        return (
            <>
                {!disabled ?
                    <Col xl={mdCol} sm={6} md={6} className={'col-6 mb-2 ovh animated fadeInUp'}>
                        <Link to={item.prefix !== null ? '/' + item.prefix : '#'} className="game2-link">
                            <div className={item.prefix !== null ? 'game2-image' : 'game2-image orange'}>
                                <img src={item.image} className="img-fluid" alt={item.name} style={style} />
                            </div>
                        </Link>
                    </Col>
                    :
                    <Col xl={mdCol} sm={6} md={6} className={'col-6 mb-2 ovh animated fadeInUp'}>
                        <Link to={'#'} onClick={this.maintance} className="game2-link">
                            <div className={item.prefix !== null ? 'game2-image' : 'game2-image orange'}>
                                <img src={item.image} className="img-fluid" alt={item.name} />
                            </div>
                        </Link>
                    </Col>
                }
            </>
        );


    }

}

export default Games;
