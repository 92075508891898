import React from 'react';
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import storage from "../../../Storage";
import { getUID, SITE_URL } from "../../../Helper";
import Terms from "./Terms";
import List from "./List";

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            uid: getUID,
            logged: false
        };
    }

    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (this.state.token !== null) {
            this.setState({ logged: true });
        }
    }

    render() {
        let { logged } = this.state;

        return (
            <>
                <Helmet>
                    <title>Affiliate - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, Alpha.Casino Slot Games" />
                    <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/affiliate" />
                </Helmet>
                <div>
                    <div style={{ background: '#161c2c url(/assets/images/mountain.png)', backgroundSize: 'cover' }} className={'p-4 wheel-content'}>
                        <Terms />
                        <h2 className={'text-center text-upper text-warning font-weight-bold'}>Affiliate</h2>
                        <p className={'text-center text-upper text-white'}>
                            Invite your friends to get free coins.
                        </p>
                        <Row>
                            <Col sm={12} xl={5} md={5} className={'m-auto'}>
                                <Card>
                                    <Card.Body className={'rounded text-center'} style={{ background: "#243042" }}>
                                        <label className="text-white text-upper">Your Link</label>
                                        {logged ?
                                            <input type="text" value={'https://' + SITE_URL + '/aff/' + this.state.uid} className={'form-control'} />
                                            :
                                            <input type="text" value={"Please login to see your link."} className={'form-control'} />
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col sm={12} xl={12} md={12} className={'mx-auto mt-3'}>
                            <Card>
                                <Card.Body>
                                    {!logged ?
                                        <div className="text-center">[ Login to see your stats ]</div>
                                        :
                                        <List />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
