import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useAuthContext } from "../../../AuthContext";
import { CONNECTOR_IDS } from "../../../utils/connectors";
import { Event, wait, decode, sendNotfication } from "../../../Helper";
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import Cookies from "js-cookie";

const Login = (props) => {
  const { t, show } = props;

  const {
    handleLogin,
    handleLogout,
    isWalletConnected,
    isWalletConnecting,
    setIsWalletConnecting,
  } = useAuthContext();

  const [isShow, setIsShow] = useState(false);
  const [effect, setEffect] = useState("zoomIn");

  const setLogin = (data) => {
    localStorage.setItem("socket_login_data", JSON.stringify(data));
    if (data.status === true) {
      sendNotfication(
        "Successfully Login, Please Wait...",
        "success",
        "bottom-left"
      );
      Cookies.set("session", data.token, { expires: 14 });
      storage.setKey("logged", true);
      storage.setKey("token", data.token);
      storage.setKey("name", data.name);
      storage.setKey("avatar", data.avatar);
      storage.setKey("email", data.email);
      storage.setKey("credit", data.credit);
      storage.setKey("room", data.room);
      storage.setKey("friends", data.friends);
      setIsWalletConnecting(false);

      wait(500).then(() => {
        window.location.reload();
      });
    } else {
      setIsWalletConnecting(false);
      sendNotfication(data.status, "success", "bottom-left");
      handleLogout();
    }
  };

  const handleShow = (e) => {
    setIsShow(true);
    setEffect("zoomIn");
  };

  const handleClose = () => {
    setIsShow(false);
    setEffect("zoomOut");
  };

  const handleConnectMetaMask = () => {
    handleLogin(CONNECTOR_IDS.metamask);
  };

  // address: "",
  // signature: "",
  // timestamp: "",

  useEffect(() => {
    socket.on(C.LOGIN_USER, (data) => {
      setLogin(decode(data));
    });

    Event.on("login_form", () => {
      handleShow();
    });
  }, []);

  useEffect(() => {
    if (!show || isWalletConnected) return;
    setIsShow(show);
  }, [show, isWalletConnected]);

  useEffect(() => {
    if (isWalletConnected) {
      setIsShow(false);
    }
  }, [isWalletConnected]);

  return (
    <>
      <button
        className={
          "btn btn-transparent btn-auth-button btn-round btn-sm no-shadow text-white px-4 font-weight-bold "
        }
        onClick={(e) => handleShow(e)}
      >
         <img
                    src="/assets/images/alph.png"
                    className="bnb-icon-home"
                    width="20" height="20" margin="3px"
                  />

        {t("Connect ")}
      
        <img
                    src="/assets/images/ic-meta-mask-custom.png"
                    className="wallet-icon-home"
                  />
      </button>
      <Modal
        size="md"
        centered={true}
        backdrop="static"
        show={isShow}
        onHide={handleClose}
        aria-labelledby="login-md-modal"
        className={"modalAuth animated " + effect}
      >
        <Modal.Header>
          <button type="button" className="close p-2" onClick={handleClose}>
            <i className={"mdi mdi-close"} />
          </button>
        </Modal.Header>
        <Modal.Body className="auth-modal p-0">
          <div className="m-auto">
            <div>
              <div className="text-center">
                <img
                  src="/assets/images/lg.png"
                  className={"img-fluid auth-logo"}
                  alt="Logo"
                />
                <div className="text-center auth-logo-text">
                  <p className="mt-0 mb-3 mt-3 font-new text-white font-15">
                    {t("mega_profit")}
                    <i className="mdi mdi-dots-horizontal mx-2 font-18 align-middle" />
                    {t("fair_games")}
                  </p>
                </div>
              </div>
              <div className="text-center">
                <p className="text-white font-18">Connect Wallet</p>
              </div>
              <div className="px-4">
                <button
                  disabled={isWalletConnecting}
                  onClick={handleConnectMetaMask}
                  className="center-root wallet-item btn btn-auth btn-block font-weight-bold no-shadow"
                >
                  <img
                    src="/assets/images/ic-meta-mask-custom.png"
                    className="wallet-icon"
                  />
                  <p className="font-new text-white font-15 wallet-name">
                    MetaMask
                  </p>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(Login);
