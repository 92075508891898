import React from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Games from "../Pages/Parts/Home/Games";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: null
        }
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    change = (e) => {
        let value = e.target.value;
        this.setState({ search: value });
    }

    render() {
        return <>
            <Helmet>
                <title>Original Games | Crypto Casino Games, Slot Games and Crypto Gambling</title>
                <meta name="keywords" content="Crypto Casino Games, Online Casino Games, Slot Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, Alpha.Casino Crypto Games" />
                <meta name="description"
                    content="Alpha.Casino is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                <meta name="og:title" content="Alpha.Casino is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                <meta name="og:description" content="Alpha.Casino is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                <link rel="canonical" href="/games" />
            </Helmet>
            <div>
                <Card className="brd7">
                    <Card.Body className="py-2">
                        <div id="st">
                            <h4 className="mt-2 mb-2 section-title"> Original Games</h4>
                            <Games />
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    }
}

export default Index;
