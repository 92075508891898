import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";
import { ethers } from "ethers";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { wait, encode } from "../Helper";
import {
  CHAIN_ID_HEX,
  CONNECTOR_IDS,
  setupBscNetwork,
  CONNECTOR_ID_KEY,
  injectedConnector,
  getCurrentProvider,
  getCurrentBscAddress,
  getCurrentConnectorId,
} from "../utils/connectors";
import Cookies from "js-cookie";
import socket from "../Socket";
import C from "../Constant";

export const AuthContext = createContext(null);
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const { library, activate, deactivate } = useWeb3React();

  const hasCookie = Cookies.get("session");
  const connectorId = getCurrentConnectorId();

  const [walletAddress, setWalletAddress] = useState();
  const [isWalletConnecting, setIsWalletConnecting] = useState(false);

  const isWalletConnected = useMemo(() => {
    return Boolean(walletAddress && hasCookie);
  }, [walletAddress, hasCookie]);

  const isWalletConnectedRef = useRef(isWalletConnected);
  const walletAddressRef = useRef(walletAddress);

  const isReconnectingWallet = useMemo(() => {
    return Boolean(!walletAddress && hasCookie);
  }, [walletAddress, hasCookie]);

  const connectBsc = async (connectorMethod) => {
    try {
      await activate(connectorMethod, null, true);

      const currentProvider = await getCurrentProvider();
      const account = await getCurrentBscAddress(currentProvider);

      return account;
    } catch (error) {
      console.log(error);
      if (error instanceof UnsupportedChainIdError) {
        const currentProvider = await getCurrentProvider();

        const hasSetup = await setupBscNetwork(currentProvider);

        if (hasSetup) {
          await activate(connectorMethod);
          const account = getCurrentBscAddress(currentProvider);
          return account;
        } else {
          handleLogout();
          return "";
        }
      } else {
        handleLogout();
        return "";
      }
    }
  };

  const handleSignMessage = async (address) => {
    setIsWalletConnecting(true);
    const currentWalletProvider = await getCurrentProvider();

    try {
      const ethersProvider = new ethers.providers.Web3Provider(
        currentWalletProvider,
        "any"
      );
      const nonce = new Date().getTime();

      const signature = await ethersProvider
        .getSigner(address)
        .signMessage(nonce.toString());

      wait(200).then(() => {
        socket.emit(
          C.LOGIN_USER,
          encode({
            address: address,
            signature: signature,
            nonce: nonce,
          })
        );
      });
    } catch (e) {
      console.log(e);
      handleLogout();
    }
  };

  const handleLogin = useCallback(
    async (connectorId, isSwitchAccount) => {
      localStorage.setItem(CONNECTOR_ID_KEY, connectorId);
      let account;

      switch (connectorId) {
        case CONNECTOR_IDS.metamask:
          account = await connectBsc(injectedConnector);
          break;
        default:
          return;
      }

      if (!account) return;
      setWalletAddress(account);

      if (isReconnectingWallet && !isSwitchAccount) return;

      handleSignMessage(account);
    },
    [connectBsc, setWalletAddress]
  );

  const handleBscChainChanged = (_chainId) => {
    if (
      _chainId !== Number(process.env.REACT_APP_BSC_CHAIN_ID) ||
      _chainId != CHAIN_ID_HEX
    ) {
      handleLogout();
    }
  };

  const handleAccountChanged = async (accounts) => {
    const newChecksumAddress = ethers.utils.getAddress(accounts[0]);

    if (
      !isWalletConnectedRef.current ||
      newChecksumAddress === walletAddressRef.current
    )
      return;

    await handleLogout();

    handleLogin(connectorId, true);
  };

  const handleLogout = useCallback(async () => {
    deactivate();
    setWalletAddress();
    setIsWalletConnecting(false);

    socket.emit(C.LOGOUT_USER);
    localStorage.clear();
    Cookies.remove("session");
    Cookies.remove("auth");
    Cookies.remove("uid");
    window.location.replace("./");
  }, [deactivate, setWalletAddress]);

  useEffect(() => {
    if (!walletAddress && connectorId && hasCookie) {
      handleLogin(connectorId);
    }
  }, [walletAddress, connectorId, hasCookie, handleLogin]);

  useEffect(() => {
    if (!library) return;

    const currentProvider = getCurrentProvider();

    currentProvider?.on?.("chainChanged", handleBscChainChanged);
    currentProvider?.on?.("accountsChanged", handleAccountChanged);

    //Clear event only for wallet connect
    return () => {
      currentProvider?.off?.("chainChanged", handleBscChainChanged);
      currentProvider?.off?.("accountsChanged", handleAccountChanged);
    };
  }, [library]);

  useEffect(() => {
    isWalletConnectedRef.current = isWalletConnected;
  }, [isWalletConnected]);

  useEffect(() => {
    walletAddressRef.current = walletAddress;
  }, [walletAddress]);

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleLogout,
        walletAddress,
        isWalletConnected,
        isWalletConnecting,
        setIsWalletConnecting,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default memo(AuthProvider);
