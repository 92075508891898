import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Faq - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, Alpha.Casino Slot Games" />
                    <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/faq" />
                </Helmet>
                <div>
                    
                <Card>
                        <Card.Body>
                            <p className="about-us-page">
                                <h4 className="text-secondary">How does a Alpha Casino work?</h4>

                                Alpha casino operates on Alph Network blockchain It enables users to engage in transactions directly with one another as opposed to relying on a central authority.

                                <h4 className="text-secondary">What is fair game in casino?</h4>

                                The game is said to be “fair” if it allows all players to have strictly the same chance of winning or losing. In other words, the same probabilities apply to every game played. This calculation is based on the entire game in question.

                                <h4 className="text-secondary">How do you play Provably fair?</h4>

                            
The gambling site creates a seed number which is then hashed and sent to the player.
<br></br>
The player adds his or her own seed.
<br></br>
The bet plays out, and the outcome is decided.
<br></br>
When the bet has played out, the player receives the seed used in the bet.
<br></br>
The player can the verify the bet.



                                <h4 className="text-secondary">How do I connect my wallet to DApp?</h4>


    1. Download a Metamask Wallet.
    <br></br>
    2. Set up your Metamask.
    <br></br>
    3. Buy ALPH or BNB or BUSD or .. as Your Base Currency. 
    <br></br>
    4. Send Coin/Token From Binance / Alph Network to Your Crypto Wallet. 
    <br></br>
    5. Connect Your Wallet.
    <br></br>
    Enjoy it.

                                <h4 className="text-secondary">How to deposit</h4>

                                After login with metamask or any web3 wallet Go to > Wallet > Choose deposit amount > Confirm metamask sign and finish.

                                <h4 className="text-secondary">How long does it take to deposit and withdraw?</h4>

                                Deposits/Withdraw from BSC happen using a state sync mechanism and take about 3-7 minutes. If you encounter any problem during deposit or Withdraw, you can visit www.bscscan.com to check your transaction. 
<h4 className="text-secondary">Is your game fair and just?</h4>

We are based on the Ethereum smart contract. All data and core logic on the blockchain are transparent, and manipulation is not possible. Every single transaction on the blockchain can be tracked on etherscan. The fairness, openness, and justness of us gaming platform are guaranteed. 
                            </p>
                        </Card.Body>
                    </Card>

                </div>
            </>
        );
    }
}
