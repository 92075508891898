import React from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import Logo from "./../Logo";
import Chat from "./../Chat";
import MobileMenu from "./../Menu/MobileMenu";
import { isMobile, Event } from "../../../../Helper";
import Login from "../../Auth/Login";
import RealTime from "./../RealTime";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: null,
      show: true,
      avatar: "/assets/images/avatar.png",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (!isMobile()) {
      this.setState({ header: this.desktop() });
    } else {
      this.setState({ header: this.mobile() });
    }
  }

  collapse = () => {
    this.setState({ show: !this.state.show });
    Event.emit("toggle_sidebar", this.state.show);
  };

  desktop = () => {
    const { t } = this.props;
    return (
      <div className="topbar" id={"topbar"}>
        <nav className="navbar-custom">
          <RealTime t={t} />
          <Row>
            <Col md="6" sm="6">
              <Logo />
              {/*  <ul class="list-unstyled topbar-nav mb-0">
                                    <li>
                                        <button class="button-menu-mobile nav-link hidden-sm" onClick={this.collapse}>
                                            <i class="dripicons-menu nav-icon"></i>
                                         </button>
                                    </li>
                                </ul>*/}
            </Col>
            <Col md="6" sm="6">
              <ul className="list-unstyled topbar-nav float-right mb-0">
                <li className="mt-3 mr-3">
                  <Login t={t} />
                </li>
                <li className={"chat-sidebar"}>
                  <Chat t={t} location={this.props.location} />
                </li>
              </ul>
            </Col>
          </Row>
        </nav>
      </div>
    );
  };

  mobile = () => {
    const { t } = this.props;
    return (
      <div className="topbar" id={"topbar"}>
        <RealTime t={t} />
        <Logo />
        <nav className="navbar-custom">
          <MobileMenu t={t} />
          <ul className="list-unstyled topbar-nav float-right mb-0 mr-1">
            <li className="mt-3 mr-3">
              <Login t={t} />
            </li>
            <li className={"chat-sidebar"}>
              <Chat t={t} location={this.props.location} />
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  render() {
    return this.state.header;
  }
}

export default Header;
