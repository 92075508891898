import { ethers } from "ethers";
import DepositWithdrawBuild from "./DepositWithdraw.json";
import ERC20Build from "./ERC20.json";
import { getCurrentProvider } from "../../utils/connectors";

export const TRANSACTION_STATUS_TYPE = {
  complete: "complete",
  failed: "failed",
  loading: "loading",
};

export const getBSCSmartContractFromJsonFile = (contractAddress, jsonFile) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_BSC_RPC_URL
    );
    const contract = new ethers.Contract(
      contractAddress,
      jsonFile.abi,
      provider
    );

    return contract;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const depositWithdrawInstance = getBSCSmartContractFromJsonFile(
  process.env.REACT_APP_ADDRESS_DEPOSIT_WITHDRAW,
  DepositWithdrawBuild
);

export const getERC20TokenInstance = (tokenAddress) => {
  const tokenInstance = getBSCSmartContractFromJsonFile(
    tokenAddress,
    ERC20Build
  );
  return tokenInstance;
};

export const convertTokenBalanceString = (value) => {
  if (!value) return 0;

  return ethers.utils.formatEther(value);
};

export const handleSendTransaction = async (data) => {
  const { transactionData, walletAddress } = data;
  if (!transactionData || !walletAddress) return;

  try {
    const currentWalletProvider = getCurrentProvider();
    const chainProvider = new ethers.providers.Web3Provider(
      currentWalletProvider,
      "any"
    );

    const signer = chainProvider.getSigner(walletAddress);
    const transaction = await signer.sendTransaction(transactionData);
    return Promise.resolve(transaction.hash);
  } catch (error) {
    console.log(error);

    return Promise.reject({
      status: TRANSACTION_STATUS_TYPE.failed,
      code: error?.code,
      message: error?.transactionData?.message || error?.reason,
    });
  }
};

export const handleGetResult = (transactionHash) => {
  return new Promise((resolve, reject) => {
    const currentWalletProvider = getCurrentProvider();
    const chainProvider = new ethers.providers.Web3Provider(
      currentWalletProvider,
      "any"
    );

    let ethersPoolingStatus;
    let receipt;
    let numberOfFunctionCalled = 0;

    ethersPoolingStatus = setInterval(async () => {
      if (++numberOfFunctionCalled === 100) {
        clearInterval(ethersPoolingStatus);
        reject({
          status: TRANSACTION_STATUS_TYPE.failed,
          message: "Something went wrong",
        });
        return;
      }

      try {
        receipt = await chainProvider.getTransactionReceipt(transactionHash);
        if (!receipt) return;

        const transaction = await chainProvider.getTransaction(transactionHash);

        let message;
        try {
          let code = await chainProvider.call(
            transaction,
            transaction?.blockNumber
          );
          message = ethers.utils.toUtf8String("0x" + code?.substr(138));
        } catch (err) {
          const code = err?.data?.replace("Reverted ", "");
          message =
            ethers.utils?.toUtf8String("0x" + (code?.substr(138) || "")) || "";
        }

        clearInterval(ethersPoolingStatus);

        if (receipt.status === 1) {
          resolve({
            ...receipt,
            status: TRANSACTION_STATUS_TYPE.complete,
            message,
          });
        } else {
          resolve({
            ...receipt,
            status: TRANSACTION_STATUS_TYPE.failed,
            message,
          });
        }
      } catch (error) {
        console.log(error);
        clearInterval(ethersPoolingStatus);
        reject({
          status: TRANSACTION_STATUS_TYPE.failed,
          message: "Error! Something went wrong",
        });
      }
    }, 2500);
  });
};

export const bscTokenApproval = async (
  tokenInstance,
  tokenAddress,
  spenderAddress,
  approvalAmount,
  walletAddress
) => {
  const MAX_INT_256 = (
    BigInt(2 ** 256) - BigInt(1)
  ).toString(); /* eslint-disable-line */

  const approvalAmountWei = ethers.utils.parseEther(approvalAmount.toString());
  let data;

  tokenInstance.interface.encodeFunctionData("approve", [
    spenderAddress,
    MAX_INT_256,
  ]);

  try {
    data = await tokenInstance.interface.encodeFunctionData("approve", [
      spenderAddress,
      MAX_INT_256,
    ]);
  } catch (error) {
    console.log(error);
    data = await tokenInstance.interface.encodeFunctionData("approve", [
      spenderAddress,
      approvalAmountWei,
    ]);
  }

  if (data) {
    return {
      from: walletAddress,
      data: data,
      to: tokenAddress,
    };
  } else {
    return {};
  }
};
