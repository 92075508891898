import React from "react";
import { useAuthContext } from "../../../AuthContext";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

const Logout = () => {
  const { handleLogout } = useAuthContext();

  const ask = () => {
    Swal.fire({
      title: "Are you sure to logout?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        handleLogout();
      }
    });
  };

  return (
    <>
      <a onClick={ask} className={"dropdown-item"}>
        <i className="dripicons-exit text-muted ml-1 mr-2 text-drop" />
        Logout
      </a>
    </>
  );
};

export default withRouter(Logout);
