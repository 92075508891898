import { InjectedConnector } from "@web3-react/injected-connector";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";

export const CONNECTOR_IDS = {
  metamask: "meta-mask",
};

export const CONNECTOR_ID_KEY = "connectorId";

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [parseInt(process.env.REACT_APP_BSC_CHAIN_ID, 10)],
});

export const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

export const getCurrentConnectorIdKey = () => {
  return typeof window !== "undefined"
    ? window.localStorage.getItem(CONNECTOR_ID_KEY)
    : null;
};

export const getCurrentProvider = () => {
  const connectorId = getCurrentConnectorIdKey();
  if (connectorId === CONNECTOR_IDS.metamask) {
    return window.ethereum;
  } else {
    return null;
  }
};

export const BSC_CHAIN_NAME =
  Number(process.env.REACT_APP_BSC_CHAIN_ID) === 97
    ? "Binance Smart Chain - Testnet"
    : "Binance Smart Chain - Mainnet";

export const CHAIN_ID_HEX = `0x${Number(
  process.env.REACT_APP_BSC_CHAIN_ID
).toString(16)}`;

export const setupBscNetwork = async (provider) => {
  if (provider) {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: CHAIN_ID_HEX,
          },
        ],
      });
      return true;
    } catch (switchError) {
      const { code: errorCode } = switchError;

      const isDeniedSwitch = errorCode === 4001;

      if (isDeniedSwitch) return false;

      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: CHAIN_ID_HEX,
              chainName: BSC_CHAIN_NAME,
              nativeCurrency: {
                name: "BNB",
                symbol: "bnb",
                decimals: 18,
              },
              rpcUrls: [process.env.REACT_APP_BSC_RPC_URL],
              blockExplorerUrls: [process.env.REACT_APP_BSC_BASE_URL],
            },
          ],
        });
        return true;
      } catch (error) {
        window.isDebug && console.error("Failed to setup the network:", error);
        return false;
      }
    }
  } else {
    window.isDebug && console.error("Can't setup the BSC network");
    return false;
  }
};

export const getCurrentBscAddress = async (currentProvider) => {
  if (!currentProvider) return;
  const provider = new ethers.providers.Web3Provider(currentProvider, "any");
  const signer = await provider.getSigner();
  const address = await signer.getAddress();

  return address;
};

export const getCurrentConnectorId = () => {
  return typeof window !== "undefined"
    ? window.localStorage.getItem(CONNECTOR_ID_KEY)
    : null;
};

export const truncateHash = (address, startLength = 6, endLength = 4) => {
  if (!address) return "";
  return `${address.substring(0, startLength)}...${address.substring(
    address.length - endLength
  )}`;
};
