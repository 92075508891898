import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Button} from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import {gameCoin} from "../../../actions/gameCoin";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import {Event, __, isValidNumber, wait, forceSatoshiFormat, sendNotfication, playAudio} from "../../../Helper";

class Bet extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            started: false,
            isLogged: storage.getKey("logged") !== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            payout: '2.00',
            inputDisabled: false,
            buttonText: 'BET',
            betNumber: 10,
            firstBetNumber: 10,
            onWin: 0,
            onLost: 0,
            stopOnWin: 0,
            isWin: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillUnmount() {
        let { engine, started} = this.state;
        engine.init = false;
        this._isMounted = false;

        document.removeEventListener('mousedown', this.handleClickOutside);

        try {
            if(started)
                document.getElementById('bet').click()
        }
        catch (e) {}
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;

        document.addEventListener('mousedown', this.handleClickOutside);

        this.props.gameCoin();
        engine.on(C.PLAY_LIMBO, (data) => this.play(data));
        engine.on(C.BUSTED_LIMBO, () => this.busted());
        engine.on(C.ERROR_LIMBO, (data) => this.error(data));
        engine.on("stop_limbo", () => this.stopAuto());
        engine.on("stop_playing", () => this.stopManual());
    }
    
    stopManual() {
        this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

            let value = parseFloat(this.state.payout);

            if(value < 1.01)
                value = 1.01;

            if(value > 1000000)
                value = 1000000;

            this.setState({ payout: parseFloat(value).toFixed(2) });
        }
    }

    stopAuto() {
        this.setState({ started: false, buttonText: 'Start Auto Mode', inputDisabled: false });
    }

    play(data) {
        if(this._isMounted){
            let { engine, bet, started } = this.state;

            let isWin = false;

            if(parseFloat(data.profit) !== 0 && parseFloat(data.profit) !== 0.00000000){
                isWin = true;
            }

            this.setState({ isWin: isWin });

            this.setCanvas(data.target);

            if(bet === 'auto')
            {
                if(started)
                {
                    wait(500)
                        .then(() =>{
                            engine.isWin = isWin;
                            engine.roundProfit = __.toNumber(data.profit) + __.toNumber(engine.roundProfit);
                            this.placeAutoBet();
                        })
                }
            }

            wait(500).then(() => {
                if(isWin){
                    playAudio('win.mp3');
                }
            })
        }
    }

    busted(){
        if(this._isMounted){
            wait(500).then(() => {
                this.setState({ inputDisabled: false });
            })
        }
    }

    placeAutoBet(){
        if(this._isMounted){
            let { engine, amount, betNumber, firstBetNumber, onWin, onLost, stopOnWin } = this.state;
        
            wait(500).then(() => {
                engine.init = true;
                engine.amount = amount;
                engine.coin = this.props.coin;
                engine.betNumber = firstBetNumber;
                engine.stopOnWin = parseInt(stopOnWin);
                engine.onWin = parseInt(onWin);
                engine.onLost = parseInt(onLost);
                engine.autoStart();
                this.setState({ betNumber: betNumber - 1 });
            })
        }
    }

    placeBet(){
        if(this._isMounted){
            let { payout, amount, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.payout = payout;
                    engine.coin   = this.props.coin;
                    engine.start();
                })
        }
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });

            if(this.state.started){
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
            }
        }
    }

    handleBet(e){
        e.preventDefault();
        let { isLogged, amount, bet, started } = this.state;

       // Check User
        if(!isLogged){
           return Event.emit('showAuthModal', true);
        }

        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({ inputDisabled: true });

        if(bet === 'auto')
        {
            if(started)
            {
                this.setState({ started: false, buttonText: 'Start Auto Mode', inputDisabled: false });
                return false;
            }
            else {
                this.setState({ started: true, buttonText: 'Stop Auto Mode' });
                this.placeAutoBet();
            }
        }
        else
        {
            this.placeBet();
        }
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;
        if(target.name === 'amount' || target.name === 'stopOnWin' || target.name === 'payout'){
            if(!isValidNumber(value)) return;
        }
        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }
        this.setState({ [target.name]: value });
    }

    showAnimation(busted){
        let self = this;
        var counter = 50;
        var interval = setInterval(function() {
            counter--;
            document.querySelector('.rocket-number').innerText = counter.toFixed(2) + 'x';
            if (counter == 0) 
            {
                document.querySelector('.rocket-number').innerText = busted + 'x';
                if(self.state.isWin){
                    document.querySelector('.rocket-number').style.color = '#c8ff4d';
                }
                else {
                    document.querySelector('.rocket-number').style.color = '#ff4949';
                }
                clearInterval(interval);
            }
        }, 1);
    }

    setCanvas(busted){
        if(this._isMounted){
            let self = this;

            let SEC = 30, LIMIT = 100, plused = 0, start = 0, cnt = 0;

            let progress = setInterval(function(){
                let cnt_str = (cnt < 10) ? '0'+cnt : ''+cnt;

                if(cnt_str >= 99){
                    plused = parseInt(start + 1);
                    start++;
                }

                if(document.querySelector('.rocket-body') !== null)
                    document.querySelector('.rocket-body').style.bottom = cnt * 35 + 'px';

                if(cnt >= 13)
                {
                    clearInterval(progress);

                    if(document.querySelector('.rocket-number') !== null)
                    {
                        self.showAnimation(busted);
                    }
                    if(document.querySelector('.rocket-body') !== null){
                        document.querySelector('.rocket-body').style.bottom = '0px';
                    }
                }

                cnt = (cnt+1)%LIMIT;

            }, SEC);
        }
    }

    betMode = (type) => {
        let text;

        if(type === 'auto'){
            text = 'Start Auto Mode';
        }
        else {
            text = 'BET';
        }
        this.setState({ bet: type, buttonText: text });
    };
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    setNumber = (e, num) => {
        e.preventDefault();
        this.setState({ betNumber: num, firstBetNumber: num });
    };

    render() {
        let { bet, started, inputDisabled } = this.state;
        return (
            <>
                <form className="w-100" onSubmit={(e) => {this.handleBet(e)}}>
                    <Row>
                        <Col xl={6} md={8} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                            BET
                                        </span>
                                    </div>
                                    <input disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                            placeholder=".." value={this.state.amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={4} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">PAYOUT</span>
                                    </div>
                                    <input
                                        ref={this.wrapperRef}
                                        disabled={inputDisabled} type="text" className="form-control text-left" id="payout"
                                        name="payout" placeholder=".." value={this.state.payout} autoComplete={"off"}
                                        onChange={this.handleInputChange} />
                                    <div className="input-group-append">
                                        <button className="btn bg-cs2 after-text no-hover npt nh" type="button"><i className="mdi mdi-close" /></button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} sm={12} className="m-auto">
                            {bet === 'manual' &&
                            <Button variant={'btn btn-md btn-block my-1 btn-bet'}
                                    disabled={inputDisabled}
                                    type="submit">
                                {this.state.buttonText}
                            </Button>
                            }
                        </Col>
                    </Row>
                    {bet === 'auto' &&
                    <>
                        <Row>
                            <Col xl={6} md={6} sm={12}>
                                <div className={"form-group mb-1 bet-input"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                Bets Num
                                            </span>
                                        </div>
                                        <input
                                            type="number"
                                            disabled={inputDisabled}
                                            className="form-control"
                                            required={true}
                                            value={this.state.betNumber}
                                            onChange={e => this.setNumber(e, e.target.value)} />
                                        <div className="input-group-append">
                                            <button onClick={e=>this.setNumber(e,10)} className={'btn btn-s-4 btn-xs'}>10</button>
                                            <button onClick={e=>this.setNumber(e,50)} className={'btn btn-s-4 btn-xs'}>50</button>
                                            <button onClick={e=>this.setNumber(e,100)} className={'btn btn-s-4 btn-xs'}>100</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} md={6} sm={12}>
                                <div className={"form-group mb-1 bet-input"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
											 <span className="input-group-text">
												Increase Amount On Win
											 </span>
                                        </div>
                                        <input
                                            disabled={inputDisabled}
                                            type="number"
                                            className="form-control"
                                            name="onWin"
                                            min="0"
                                            max="100"
                                            autoComplete={"off"}
                                            value={this.state.onWin}
                                            onChange={e => this.setState({ onWin: e.target.value })} />
                                        <div className="input-group-append">
                                            <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} md={6} sm={12}>
                                <div className={"form-group mb-1 bet-input"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
											<span className="input-group-text">
												Increase Amount On Lost
                                            </span>
                                        </div>
                                        <input
                                            disabled={inputDisabled}
                                            type="number"
                                            className="form-control"
                                            name="onLost"
                                            min="0"
                                            max="100"
                                            autoComplete={"off"}
                                            value={this.state.onLost}
                                            onChange={e => this.setState({ onLost: e.target.value })} />
                                        <div className="input-group-append">
                                            <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} md={6} sm={12}>
                                <div className={"form-group mb-1 bet-input"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
											 <span className="input-group-text">
												Stop On Win
											 </span>
                                        </div>
                                        <input
                                            disabled={inputDisabled}
                                            type="text"
                                            className="form-control"
                                            name="stopOnWin"
                                            placeholder=".."
                                            value={this.state.stopOnWin}
                                            autoComplete={"off"}
                                            onChange={this.handleInputChange} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2 mr-1'} alt=""/>
                                                {this.props.coin}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={5} className={'m-auto'}>
                                <div className={ "form-group rev mb-1 mt-2"}>
                                    <Button variant={'btn btn-md btn-block btn-bet'}
                                            type="submit">
                                        {this.state.buttonText}
                                        {started &&
                                            <><div className={'ml-2 spinner-border spinner-border-sm'} /></>
                                        }
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>
                    }
                </form>
                    <div className="btn-group btn-group-toggle mb-1 ml-1" data-toggle="buttons">
                       <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active': 'btn bg-cs2 btn-xs '}>
                            <input disabled={started} onClick={ e => this.betMode('manual')} type="radio" /> Manual
                        </label>
                       <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active': 'btn bg-cs2 btn-xs '}>
                            <input disabled={started} onClick={ e => this.betMode('auto')} type="radio" /> Auto
                        </label>
                    </div>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
