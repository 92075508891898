import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import DepositAndWithdraw from "./DepositAndWithdraw";
import { isMobile } from "../../../../Helper";
import { Event } from "../../../../Helper";
class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      wallet: "deposit",
      headerText: "Deposit Archive",
      padding: "",
      paddingHeader: "",
    };
    this.handleWallet = this.handleWallet.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    if (isMobile()) {
      this.setState({ padding: "p-1", paddingHeader: "px-2" });
    }

    Event.on("deposit_archive_back", () => {
      this.setState({ headerText: "Deposit Archive" });
    });

    Event.on("withdraw_archive_back", () => {
      this.setState({ headerText: "Withdraw Archive" });
    });
  }

  handleWallet(wallet) {
    let text = wallet === "deposit" ? "Deposit Archive" : "Withdraw Archive";
    this.setState({ show: true, wallet: wallet, headerText: text });
  }

  handleClose(wallet) {
    this.setState({ show: false });
  }

  render() {
    return (
      <>
        <Modal
          size={"lg"}
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="wallet-md-modal"
        >
          <Modal.Header className={this.state.paddingHeader}>
            <button
              onClick={this.showArchive}
              className={"btn bg-cs2 btn-xs text-white"}
            >
              {this.state.headerText}
            </button>
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body className={this.state.padding + " wallet-modal"}>
            <DepositAndWithdraw />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Alert;
